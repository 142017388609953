@media only screen and (min-width: 768px) and (max-width: 1366px) {
  .slide-img {
    width: 320px;
  }
}
@media only screen and (min-width: 300px) and (max-width: 640px) {
  p.banner-data {
    width: 100%;
  }
  h4.banner-heading {
    width: 65%;
  }
  .mobile-float {
    float: none;
  }
  .sideimg1 {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
  .div-center1 {
    top: 27%;
  }
  .div-center {
    top: 20%;
    left: 0%;
  }
  .icon-div {
    padding: 0 0px;
  }
  .icon-div h4 {
    font-size: 18px;
    margin-left: 0px;
  }
  p.fellow-desc1 span {
    padding: 5px 15px;
    margin: 5px;
    display: block;
  }
  .activiti-scroll {
    height: 649px;
    min-height: 668px;
    overflow-y: scroll;
  }
  .tile .tile-image .new {
    width: 50%;
  }
  button.btn1 {
    margin-top: 4em;
  }
  .padding-main {
    padding: 10px 16px;
  }
  .activepopup-left {
    width: 100%;
  }
  .activepopup-right {
    width: 100%;
  }
  .modal-dialog {
    width: 97% !important;
  }
  .shareblog-popup {
    width: 350px !important;
    left: 0% !important;
  }
  .sideimg {
    margin-top: 50px;
    clip-path: polygon(0% 0, 100% 0%, 100% 100%, 0% 100%);
    margin-bottom: 20px;
  }
  .padding-main1 {
    padding: 40px 30px 0px;
  }
  .tile {
    /* width: 320px; */
    min-height: 300px;
  }
  .tile.mobile-width {
    width: 320px;
  }
  p.icon-text {
    margin-left: 4.5em;
  }
  .icon-box {
    width: 50px;
    height: 50px;
    padding: 10px 0;
    text-align: center;
    background: #f1c317;
    display: block;
    position: absolute;
  }
  .social-media-links {
    margin-top: 1em;
    height: 530px;
  }
  .iframe-div {
    padding-left: 0px;
  }
  .slide-img {
    width: 100%;
  }
  .mobile-about-member {
    display: block !important;
  }
  .carousel-content {
    padding: 20px;
  }
}
@media screen and (min-width: 300px) and (max-width: 700px) {
  .text-white1 {
    font-size: 13px;
    color: #ffffff;
  }
  .home-desciption {
    height: 140px;
  }
  p.footer-links a {
    margin: 0 55px 0 0;
  }
}
@media screen and (min-width: 280px) and (max-width: 344px) {
  .text-white1 {
    font-size: 11px;
    color: #ffffff;
  }
  .home-desciption {
    height: 140px;
  }
  p.footer-links a {
    margin: 0 50px 0 0;
}
}
@media screen and (min-width: 300px) and (max-width: 640px) {
  .slider {
    overflow: hidden; 
  }

  .slides {
    display: flex;
    transition: transform 0.5s ease-in-out; 
  }

  .slide {
    min-width: 100%; 
  }
}
@media (max-width: 640px) {
  .prev,
  .next {
    display: none;
  }
}