@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
/* css */
body {
  font-family: "Lato", sans-serif;
}
.text-white {
  color: #ffffff;
}
.text-white1 {
  color: #ffffff;
}
.text-black {
  color: #000000;
}
.l-float {
  float: left;
}
.pl0 {
  padding-left: 0 !important;
}
.pr0 {
  padding-right: 0 !important;
}
.padd0 {
  padding: 0 !important;
}
/* .padd15 {
  padding: 15px !important;
} */
.text-right {
  text-align: right;
}
.separator {
  height: 4px;
  width: 68px;
  margin: 5px 0 20px 0px;
  background: #f9c910;
}
a {
  text-decoration: unset !important;
}
.padd-8 {
  padding: 8px;
}
.padding-main {
  padding: 10px 30px;
}
.padding-main1 {
  padding: 40px 30px;
}
.bg-light-gray {
  background-color: #f6f6f6;
}
.fw-700 {
  font-weight: 700;
}
.fw-300 {
  font-weight: 300;
}
/* css */
/* menu */
.menu-bg {
  background: #1c3671;
}
.menu-bg1 {
  transition: top 0.5s ease;
  background: #f1c317;
}
.scrolling-navbar {
  transition: background-color 0.5s ease, top 0.5s ease;
}

li.nav-item .nav-link {
  color: #000000;
  font-weight: 400;
}
li.nav-item {
  padding: 0 20px;
  font-size: 18px;
  font-weight: 500;
}
li.nav-item.active a {
  font-weight: 700;
  color: #1c3671;
}
li.nav-item a {
  cursor: pointer !important;
}
.logo-width {
  width: 50%;
}
/* li.nav-item.active {
  border-bottom: 3px solid #ebb700;
} */
/* menu */

/* fellowship */
.fellowship-bg {
  background-color: #112e57;
}
p.fellow-desc {
  font-size: 24px;
  line-height: 32px;
}
.tile {
  width: 100%;
  background-color: #fff;
  padding: 20px;
  min-height: 340px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.tile-whatsnew {
  width: 100%;
  background-color: #fff;
  padding: 20px;
  min-height: 540px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.tile .tile-image,
.tile-whatsnew .tile-image {
  height: 230px;
  margin-bottom: 15px;
}
.tile .tile-image.show-overlay .tile-overlay,
.tile-whatsnew .tile-image.show-overlay .tile-overlay {
  display: block;
}
.tile .tile-image .flag,
.tile-whatsnew .tile-image .flag  {
  color: #fff;
  position: relative;
  bottom: 1.5em;
  width: 80%;
  display: block;
  font-size: 20px;
  letter-spacing: 0px;
  line-height: 22px;
  padding: 17px 19px 16.7px;
}
.flag.bg-primary-d1-yellow div {
  font-weight: 700;
}
.flag {
  border-color: rgb(249, 201, 16);
}
.bg-primary-d1-yellow {
  background-color: #f9c910;
}
.tile .tile-image .flag:after,
.tile-whatsnew .tile-image .flag:after {
  content: "";
  position: absolute;
  left: -1px;
  top: 0;
  margin-left: 100%;
  width: 0;
  height: 100%;
  border-bottom: 55px solid transparent;
  border-top: 0 solid transparent;
  border-left: 35px solid #000;
  border-left-color: inherit;
  font-size: 0;
  line-height: 0;
}
.tile .tile-caption,
.tile-whatsnew .tile-caption {
  margin: 1.7em 0.8em 0px;
  color: #112e57;
  font-size: 24px;
  line-height: 29px;
}
.sideimg {
  clip-path: polygon(20% 0, 100% -1%, 100% 100%, 0% 100%);
  height: 340px;
  width: 100%;
  overflow: hidden; /* Ensures the image stays within the box */
  position: relative; /* Ensures proper positioning for the img */
}
.sideimg img {
  height: 100%;
  width: 100%;
  object-fit: cover; /* Ensures the image covers the entire box */
  position: absolute; /* Ensures the image fits within the clipped area */
  top: 0;
  left: 0;
}

button.btn {
  background-color: #f9c910;
  color: #000;
  font-size: 17px;
  padding: 13px 30px 14px;
  min-height: 30px;
  line-height: 13px;
}
button.btn:hover {
  background-color: #c7a00c;
  color: #000000;
}
p.fellow-desc1 {
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.5px;
  text-align: justify;
}
.div-center {
  z-index: 1;
  position: relative;
  top: 50%;
  left: 3.2%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.div-center1 {
  z-index: 1;
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.width-100 {
  width: 100%;
}
/* fellowship */

/* whats new */
.tile .tile-image .new ,
.tile-whatsnew .tile-image .new {
  color: #fff;
  position: relative;
  bottom: 16.5em;
  width: 29%;
  display: block;
  font-size: 14px;
  line-height: 10px;
  padding: 8px;
  text-align: center;
  margin: 10px;
  border-radius: 30px;
}
p.news-text {
  margin: 14px 0;
  color: #080808;
  font-size: 16px;
  line-height: 1.3;
}
button.btn1 {
  background-color: #f9c910;
  color: #000;
  font-size: 16px;
  padding: 8px 20px 8px;
  min-height: 40px;
  line-height: 10px;
  font-weight: 500;
}
.dropdown {
  display:flex;
  justify-content: flex-end;
}
.btn-dropdown.dropdown button.dropdown-toggle.btn.btn-primary {
  margin-right: 8px;
  background-color: #ffffff;
  color: #000;
  font-size: 16px;
  padding: 10px 15px 10px;
  min-height: 45px;
  line-height: 15px;
  border: 1px solid #a1a1a100;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  margin-bottom: 20px;
}
/* whats new */

/* about us */
.border-bt {
  border-bottom: 2px solid #f1c317;
}
.carousel-control-next-icon,
.carousel-control-prev-icon {
  display: inline-block;
  background-repeat: no-repeat;
  background-position: 50%;
  background-color: #000;
  padding: 15px;
  border-radius: 50px;
  background-size: 70% 70% !important;
  width: 1rem !important;
  height: 1rem !important;
}
p.about-text {
  font-size: 24px;
  font-weight: 600;
  color: #727272;
}
.img-member img {
  border-radius: 100%;
  width: 150px;
  height: 150px;
}
.img-activity img {
  border-radius: 100%;
  width: 70px;
  height: 70px;
}
.fs-18 {
  font-size: 18px;
}
.fs-16 {
  font-size: 16px;
}
.about-p p {
  line-height: 0.9;
}
.about-p.subline {
  margin-bottom: 30px;
}
/* about us */

/* Activities */
.sub-section-icon {
  display: inline-block;
  padding: 4px;
  border-radius: 30px;
  border: 1px solid #e9e9e9;
  width: 40px;
  height: 40px;
  cursor: pointer;
  margin: 0 3px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}
.ml-19 {
  margin-left: 19em;
}
.icon-div {
  display: flex;
  justify-content: flex-end;
    padding: 0px 20px;
}
.modal-dialog {
  max-width: 1000px !important; /* Adjust this value to increase the width */
  width: 90% !important; /* Optional: Set a percentage width to ensure responsiveness */
}
.modal-header {
  border-bottom: 0px !important;
}
.modal-footer {
  border-top: 0px !important;
  justify-content: center !important;
}
.width-80 {
  width: 80%;
}
.width-60 {
  width: 60%;
}
img.width-16{
  width: 16%;
}
.activepopup-left {
  width: 65%;
  float: left;
  padding: 10px 0;
}
.activepopup-right {
  width: 35%;
  float: left;
  padding: 20px 5px;
  text-align: center;
  height: 270px;
  overflow: overlay;
}
img.activity-pimg {
  width: 180px;
}
.data {
  padding: 15px;
  margin: 10px;
}
.data p {
  margin-bottom: 2px;
  font-size: 14px;
}
p.fellow-desc1 span {
  background: #ebf1ff;
  padding: 5px 15px;
  border-radius: 5px;
  margin: 0px 10px 0 0px;
}
.desk-ml{
  margin-left: -22px;
}
/* Activities */

/* For Webkit browsers (Chrome, Safari) */
::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

::-webkit-scrollbar-track {
  background: #f1f1f1; /* Color of the track */
}

::-webkit-scrollbar-thumb {
  background: #1c3671; /* Color of the thumb */
  border-radius: 5px; /* Radius of the thumb */
}

/* footer */
.copyright {
  background: #32302f !important;
  padding: 50px 0 0;
}
.width-16 {
  width: 16%;
}
.width-20 {
  width: 20%;
}
.p20 {
  padding: 20px;
}
p.footer-links a {
  margin: 0 60px 0 0;
}
p.footer-links1 a {
  margin: 0 38px 0 0;
}
p.footer-links2 a {
  margin: 0 28px 0 0;
}
.pr12 {
  padding-right: 12px;
}
/* footer */

/* home */

/* banner */
.carousel-item.banner {
  height: 550px;
}
.carousel-item.banner img {
  height: 550px;
  object-fit: cover;
}
p.banner-data {
  background-color: rgba(68, 61, 55, 0.9);
  width: 50%;
  text-align: center;
  margin: 0 auto;
  padding: 15px;
}
p.banner-data h5 {
  font-weight: 700;
}
h4.banner-heading {
  background-color: #ed7439;
  width: 20%;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 1em;
  padding: 10px;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 2px;
}
/* banner */

/* slider */
.slider {
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
}


.slide {
  box-sizing: border-box;
  flex: 0 0 33.33%; /* Adjust width based on number of slides */
  padding: 0 0px;
}

.slide .col-md-4 {
  width: 100%; 
}
.carousel-content {
  padding: 22px;
  text-align: center;
}

.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  outline: none;
  padding: 10px;
  z-index: 100;
}

.prev {
  left: 0;
}

.next {
  right: 0;
}
.slide-img {
  width: 380px;
}
.carousel-content p {
  text-align: center;
  font-size: 14px;
  color: #727272;
  padding: 20px 0;
}
.carousel-content.p20 h5 {
  font-weight: 700;
  font-size: 18px;
  color: #26211d;
}
.more-link.btn.btn-default {
  background: none;
  text-transform: uppercase;
  font-size: 13px !important;
  border: 2px solid !important;
  border-radius: 0 !important;
  padding: 10px 30px;
}
a.more-link.btn.btn-default:hover {
  background-color: #f1c317;
  border: 2px solid #f1c317 !important;
}
/* slider */
.carousel-content .p20 h5 {
  font-size: 18px;
}
img.counter-icon {
  width: 60px;
}
.bg-image {
  background-image: url("../images/Icons_BG.jpg");
}
.bg-color {
  opacity: 0.9;
  background: #32302f;
  padding: 25px 0;
}
.how-to-help {
  background: #fbfaf8;
}
.pb30 {
  padding-bottom: 30px;
}
.icon-box {
  width: 68px;
  height: 68px;
  padding: 20px 0;
  text-align: center;
  background: #f1c317;
  display: block;
  position: absolute;
}
p.icon-text {
  margin-left: 6em;
  margin-bottom: 2.5em;
}
p.icon-text h6 {
  font-size: 19px;
  margin-bottom: 15px;
}
.social-media-links {
  background: #ffffff;
  height: 495px;
  padding: 20px;
}
.iframe {
  height: 360px;
}
.iframe-div {
  float: left;
  margin-top: 10px;
  padding-left: 25px;
  text-align: center;
}
.hide-controls .carousel-control-prev,
.hide-controls .carousel-control-next {
  display: none;
}
img.logo-height {
  max-width: 100%;  
  width: auto;      
  max-height: 55px; 
  min-height: auto; 
}
/* home */

.loaderapp {
  text-align: center;
  padding: 11em 0;
}
.sideimg1 {
  clip-path: polygon(0 0, 97% 0, 80% 100%, 0% 100%);
  height: 340px;
  width: 100%;
  overflow: hidden; /* Ensures the image stays within the box */
  position: relative; /* Ensures proper positioning for the img */
}

.sideimg1 img {
  height: 100%;
  width: 100%;
  object-fit: cover; /* Ensures the image covers the entire box */
  position: absolute; /* Ensures the image fits within the clipped area */
  top: 0;
  left: 0;
}
.captcha-text {
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 3px;
  background: #f0f0f0;
  padding: 5px;
  border-radius: 5px;
  display: inline-block;
  margin-top: 10px;
  margin-bottom: 10px;
}

.form-control {
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  box-sizing: border-box;
}

.is-invalid {
  border-color: red;
}

.invalid-feedback {
  color: red;
  margin-top: 5px;
}

.btn {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.btn:disabled {
  background-color: #cccccc;
}
.reload-btn {
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
  font-size: 32px;
  margin-top: -22px;
  vertical-align: middle;
}

.reload-btn:hover {
  color: #0056b3;
}
.sub-section-icon2 span {
  display: flex;
  border-radius: 30px;
  justify-content: center;
  text-align: center;
  border: 1px solid #e9e9e9;
  width: 40px;
  cursor: pointer;
  height: 40px;
  font-size: 25px;
  color: rgba(0, 0, 255, 0.705);
  font-weight: 700;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}
.sub-section-icon2 {
  display: flex;
  justify-content:flex-end;
}
.shareblog-popup{
  width: 400px !important;
    text-align: center;
    display: block !important;
    top: 15% !important;
    left: 35% !important;
}
.shareblog-content{
  padding: 0 20px 20px;
}
.share-icon {
  padding: 2px;
}
p {
  margin-top: 0;
  margin-bottom: 0.5rem !important;
}
.desk-space-between{
  display: flex;
  justify-content: space-between;
}

.home-desciption{
  height: 80px;
}


.slides {
  display: flex;
  transition: transform 0.3s ease-in-out;
}

.circle-indicator {
  width: 10px;
  display: none;
  height: 10px;
  background-color: #ccc;
  border-radius: 50%;
  border: none;
  margin: 0 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.circle-indicator.active {
  background-color: #333;
}

@media (max-width: 640px) {
  .circle-indicator {
    margin-top: 15px;
    display: inline-block;
  }

  .prev,
  .next {
    display: flex;
  }
}
.circle-indicator1 {
  width: 10px;
  display: none;
  height: 10px;
  background-color: #ccc;
  border-radius: 50%;
  border: none;
  margin: 0 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.circle-indicator1.active {
  background-color: #333;
}

@media (max-width: 640px) {
  .circle-indicator1 {
    margin-top: 15px;
    display: inline-block;
  }

  .prev,
  .next {
    display: flex;
  }
}
